
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class StickyColumnLayoutV2 extends Vue {
  @Prop({ type: String, default: '1300px' }) readonly maxWidth!: string
  @Prop({ type: String, default: 'white' }) readonly backgroundColor!: string
  @Prop({ type: Boolean, default: false }) readonly singleColumn!: boolean
  @Prop({ type: Boolean, default: false }) readonly fullWidth!: boolean
}
